import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  selectType(ContentName){
    var i, tabName, ntabName, contents, stabs, ntabs;
    tabName = 'm' + (ContentName);
    ntabName = 'n' + (ContentName);
    contents = document.getElementsByClassName("content");
    for (i = 0; i < contents.length; i++) {
      contents[i].className = contents[i].className.replace(" active", "");
    }
    document.getElementById(ContentName).className += " active";
    stabs = document.getElementsByClassName("side-item");
    for (i = 0; i < stabs.length; i++) {
      stabs[i].className = stabs[i].className.replace(" active", "");
    }
    ntabs = document.getElementsByClassName("nav-item");
    for (i = 0; i < ntabs.length; i++) {
      ntabs[i].className = ntabs[i].className.replace(" active", "");
    }
    document.getElementById(tabName).className += " active";
    document.getElementById(ntabName).className += " active";
  }

}
