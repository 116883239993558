  <div class="side-item active" id="mmain" (click)="selectType('main')">
    <div class="side-name" data-tooltip="Name">Portfolio</div>
    <div class="side-image"><img src="http://beaend.ru/beaend.svg" height="75%" style="display:block;"/></div>
    <div class="side-footer" data-tooltip="Краткая основная информация обо мне">Основное</div>
  </div>
  <div class="side-item-end"></div>
  <div class="side-item" id="mweb" (click)="selectType('web')">
    <div class="side-name">Web-design</div>
    <div class="side-image"><i class="fas fa-code"></i></div>
    <div class="side-footer">Тип делаю сайты</div>
  </div>
  <div class="side-item-end"></div>
  <div class="side-item" id="mdesign" (click)="selectType('design')">
    <div class="side-name">Design</div>
    <div class="side-image"><i class="fas fa-paint-brush"></i></div>
    <div class="side-footer">Дизайн</div>
  </div>
<!--  <div class="side-item-end"></div>
  <div class="side-item" id="mgame_lvl" (click)="selectType('game_lvl')">
    <div class="side-name">LVL-design <sup>Game</sup></div>
    <div class="side-image"><i class="fas fa-drafting-compass"></i></div>
    <div class="side-footer">Игровое окружение</div>
  </div>
  <div class="side-item-end"></div>
  <div class="side-item" id="mgame_ui" (click)="selectType('game_ui')">
    <div class="side-name">UX/UI <sup>Game</sup></div>
    <div class="side-image"><i class="fas fa-expand"></i></div>
    <div class="side-footer">Интерфейсы для игр</div>
  </div>
  <div class="side-item-end"></div>
  <div class="side-item" id="mgame_fx" (click)="selectType('game_fx')">
    <div class="side-name">FX <sup>Game</sup></div>
    <div class="side-image"><i class="fas fa-skull-crossbones"></i></div>
    <div class="side-footer">Игровые спецэффекты</div>
  </div>
  <div class="side-item-end"></div>-->
<!--  <div class="side-item" id="mprog" (click)="selectType('prog')">
    <div class="side-name" style="margin-left:0px; font-size:2vw;">Programming</div>
    <div class="side-image"><i class="fas fa-cogs"></i></div>
    <div class="side-footer">Ничего не знаю, не умею</div>
  </div> -->
  <div class="side-item-end"></div>
  <div class="side-item" id="mphoto" (click)="selectType('photo')">
    <div class="side-name">Photo</div>
    <div class="side-image"><i class="fas fa-camera"></i></div>
    <div class="side-footer" data-tooltip="Да, да. Меня интересует не только компьютер.">Что-то человеческое</div>
  </div>
