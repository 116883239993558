export const profile_one = [
  {
    web_beaend: {
      name: 'beaend site',
      sub_name: 'Мой личный сайт',
      icon_url: 'http://beaend.ru/beaend.svg',
      live_url: 'http://beaend.ru',
      header: {
        todoone: '',
        todotwo: ''
      },
      description: 'Сайт сделал сам для себя. ' +
        'Написан на Angular. ',
      type: 'site',
      sites_block: {
        site_owner: 'beaend',
        site_owner_url: 'http://beaend.ru',
        site_owner_logo: 'http://beaend.ru/beaend.svg'
      },
      pen_block: {
        pen_forked: false,
        pen_forked_owner: '',
        pen_forked_url: ''
      }
    },
    web_beaend_blog: {
      name: 'beaend blog',
      sub_name: 'Блог для сайта. Не факт что будет существовать и работать)',
      icon_url: 'http://beaend.ru/beaend.svg',
      live_url: 'http://beaend.ru/blog',
      header: {
        todoone: '',
        todotwo: ''
      },
      description: 'Сделал дизайн блога для сайта. Очень сомневаюсь что у меня он будет, но идеи были и вот он.',
      type: 'site',
      sites_block: {
        site_owner: 'beaend',
        site_owner_url: 'http://beaend.ru',
        site_owner_logo: 'http://beaend.ru/beaend.svg'
      },
      pen_block: {
        pen_forked: false,
        pen_forked_owner: '',
        pen_forked_url: ''
      }
    },
    web_nekouji: {
      name: 'Project: Nekouji',
      sub_name: '',
      icon_url: 'http://nekouji.ru/nekouji.svg',
      live_url: 'http://nekouji.ru',
      header: {
        todoone: '',
        todotwo: ''
      },
      description: ' ',
      type: 'site',
      sites_block: {
        site_owner: 'Nekouji',
        site_owner_url: 'http://nekouji.ru',
        site_owner_logo: 'http://nekouji.ru/nekouji.svg'
      },
      pen_block: {
        pen_forked: false,
        pen_forked_owner: '',
        pen_forked_url: ''
      }
    },
    web_xaio: {
      name: 'Xaio - Помошник Стримера',
      sub_name: '',
      icon_url: 'http://xaio.ru/logo.svg',
      live_url: 'http://xaio.ru',
      header: {
        todoone: '',
        todotwo: ''
      },
      description: ' ',
      type: 'site',
      sites_block: {
        site_owner: 'Beaend',
        site_owner_url: 'http://beaend.ru',
        site_owner_logo: 'http://beaend.ru/beaend.svg'
      },
      pen_block: {
        pen_forked: false,
        pen_forked_owner: '',
        pen_forked_url: ''
      }
    },
    web_1: {
      name: '404 - Страница не найдена',
      sub_name: 'Страничке одиноко',
      icon_url: 'http://beaend.ru/beaend.svg',
      live_url: 'http://codepen.io/beaend/embed/eodNxJ/?theme-id=modal',
      header: {
        todoone: '',
        todotwo: ''
      },
      description: 'Веб страница ищет себе пару.',
      type: 'pen',
      sites_block: {
        site_owner: '',
        site_owner_url: '',
        site_owner_logo: ''
      },
      pen_block: {
        pen_forked: true,
        pen_forked_owner: 'joshuaward',
        pen_forked_url: 'https://codepen.io/joshuaward/pen/YMyPWr'
      }
    },
  }
];
