export const profile_content = [
  {
      name: 'main',
      content_name: 'beaend',
      content_item: {
          some: '',
          description: 'Основной инфы нет, есть только кнопочка в вебе.',
          url_name: 'Больше здесь',
          url: '#'
      },
      content_footer_text: 'Ещё немного:',
      sub_items: [
          {
              id: 'main_1',
              img: '',
              text: '123',
              url: ''
          },
          {
              id: 'main_2',
              img: '',
              text: '123',
              url: ''
          }
      ]
  },
  {
    name: 'web',
    content_name: 'Web',
    content_item: {
      some: '',
      description: 'Снизу картинка с логотипом - тыкни.',
      url_name: 'Больше здесь',
      url: '#'
    },
    content_footer_text: 'Последние Сайты:',
    sub_items: [
      {
        id: 'web_beaend',
        img: 'http://beaend.ru/beaend.svg',
        text: 'beaend.ru',
        url: ''
      },
      {
        id: 'web_beaend_blog',
        img: 'https://cdn.discordapp.com/attachments/624479270110232577/679418455992303646/unknown.png',
        text: 'beaend.ru blog',
        url: ''
      }, /*
        {
          id: 'web_nekouji',
          img: 'http://nekouji.ru/nekouji.svg',
          text: 'Project: Nekouji',
          url: ''
        },
        {
          id: 'web_xaio',
          img: 'https://cdn.discordapp.com/attachments/624479270110232577/651375455152111647/unknown.png',
          text: 'Xaio',
          url: ''
        },
        {
          id: 'web_rfup',
          img: '',
          text: 'RE: Life',
          url: ''
        }*/
      {
        id: 'web_1',
        img: 'https://screenshot.codepen.io/1354170.eodNxJ.724f680e-c645-457e-8253-ca25ec96feae.png',
        text: 'Ошибка 404',
        url: ''
      },
    ]
  },
  {
    name: 'design',
    content_name: 'Дизайн',
    content_item: {
      some: '',
      description: 'Мои разные идеи в дизайне.',
      url_name: '',
      url: '#'
    },
    content_footer_text: 'Парочка логотипчиков:',
    sub_items: [
      {
        id: 'design_beaend',
        img: 'http://beaend.ru/beaend.svg',
        text: 'Мой логотип',
        url: ''
      },
      {
        id: 'design_nekouji',
        img: 'http://nekouji.ru/nekouji.svg',
        text: 'Project: Nekouji',
        url: ''
      },/*
        {
          id: 'web_xaio',
          img: 'https://cdn.discordapp.com/attachments/624479270110232577/651375455152111647/unknown.png',
          text: 'Xaio',
          url: ''
        },
        {
          id: 'web_rfup',
          img: '',
          text: 'RE: Life',
          url: ''
        }*/
    ]
  }, /*
    {
      name: 'game_lvl',
      content_name: 'Игровой дизайн уровней',
      content_item: {
        some: '',
        description: '123',
        url_name: 'Больше здесь',
        url: '#'
      },
      content_footer_text: 'Ещё немного:',
      sub_items: [
        {
          id: 'lvl_1',
          img: '',
          text: '',
          url: ''
        },
        {
          id: 'lvl_2',
          img: '',
          text: '',
          url: ''
        }
      ]
    },
    {
      name: 'game_ui',
      content_name: 'Внутриигровой интерфейс',
      content_item: {
        some: '',
        description: '123',
        url_name: 'Больше здесь',
        url: '#'
      },
      content_footer_text: 'Ещё немного:',
      sub_items: [
        {
          id: 'ui_1',
          img: '',
          text: '',
          url: ''
        },
        {
          id: 'ui_2',
          img: '',
          text: '',
          url: ''
        }
      ]
    },
    {
      name: 'game_fx',
      content_name: 'Спецэффекты для игр',
      content_item: {
        some: '',
        description: '123',
        url_name: 'Больше здесь',
        url: '#'
      },
      content_footer_text: 'Ещё немного:',
      sub_items: [
        {
          id: 'fx_1',
          img: '',
          text: '',
          url: ''
        },
        {
          id: 'fx_2',
          img: '',
          text: '',
          url: ''
        }
      ]
    },*/ /*
    {
      name: 'prog',
      content_name: 'Программирование',
      content_item: {
        some: '',
        description: '123',
        url_name: 'Больше здесь',
        url: '#'
      },
      content_footer_text: 'Ещё немного:',
      sub_items: [
        {
          id: 'prog_1',
          img: '',
          text: '',
          url: ''
        },
        {
          id: 'prog_2',
          img: '',
          text: '',
          url: ''
        }
      ]
    },*/
    {
      name: 'photo',
      content_name: 'Фотографии',
      content_item: {
        some: '',
        description: 'Обожаю фотографировать природу',
        url_name: 'Больше здесь',
        url: '#'
      },
      content_footer_text: 'Ещё несколько фотографий',
      sub_items: [
        {
          id: 'photo_1',
          img: 'https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/62242828_746849839050839_4095843332066854162_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=hMSENDoKOMwAX_DnX56&oh=9461d07cbfcff77b89a1c0ccd5c61f74&oe=5ECACC4E',
          text: 'Просто гитара',
          url: ''
        },
        {
          id: 'photo_2',
          img: 'https://instagram.fhel3-1.fna.fbcdn.net/v/t51.2885-15/e35/75394643_2549403755294668_7878096610644411971_n.jpg?_nc_ht=instagram.fhel3-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=Ii0teGasa_IAX-Le1ir&oh=a70b917d51f245c2f22551a83a9bd139&oe=5EBB78E5',
          text: 'Оно было действительно так',
          url: ''
        }
      ]
  }
];
