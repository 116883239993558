import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-err404',
  templateUrl: './err404.component.pug',
  styleUrls: ['./err404.component.scss']
})
export class Err404Component implements OnInit {
  title = 'Error - 404';

  constructor(private titleService:Title) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
