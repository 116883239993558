import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { NavComponent } from './nav/nav.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileSideComponent } from './profile/profile-side/profile-side.component';
import { ProfileOpenComponent } from './profile/profile-open/profile-open.component';
import { ProfileNavComponent } from './profile/profile-nav/profile-nav.component';
import { Err404Component } from './err404/err404.component';
import { TestComponent } from './test/test.component';

import { ModalComponent, SafePipe } from './_components';
import { BlogComponent } from './blog/blog.component';
import { PostComponent } from './post/post.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ProfileComponent,
    IndexComponent,
    Err404Component,
    ProfileOpenComponent,
    TestComponent,
    ProfileSideComponent,
    ProfileNavComponent,
    ModalComponent,
    SafePipe,
    BlogComponent,
    PostComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
