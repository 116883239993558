import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { posts } from './posts';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogComponent implements OnInit {
  posts = posts;
  constructor() { }

  ngOnInit(): void {
  }

  share() {
    window.alert('Post alert');
  }
  close(){}
}
