<app-nav></app-nav>
<app-profile-nav></app-profile-nav>
<div class="nav" id="mobile-nav"><div class="sidebar-show"><div class="nav-link"><i style="font-size: 2rem;" class="fas fa-bars"></i></div></div></div>
<main>
  <div id="page-middle">
    <div *ngFor="let content of contents" class="content" [id]="content.name">
      <div class="content-name">{{content.content_name}} </div>
      <div class="content-item">
        <div class="content-item-desc">
          {{content.content_item.description}}
          <a [href]="content.content_item.url"><b>{{content.content_item.url_name}}</b></a>
        </div>
      </div>
      <div class="content-footer">
        <p>{{content.content_footer_text}}</p>
      </div>
      <div class="sub-list">
        <div *ngIf="currentWindowWidth < 768; then mobile_url else with_modal"></div>
        <ng-template #with_modal>
          <div *ngFor="let sub_content of content.sub_items" class="sub-item" (click)="openModal('modal_'+sub_content.id)">
            <jw-modal [id]="'modal_'+sub_content.id">
              <app-profile-open
                [content_id] = "sub_content.id"></app-profile-open>
            </jw-modal>
            <img *ngIf="sub_content.img" [src]="sub_content.img" width="100%" height="100%"/>
            <div class="sub-text">{{sub_content.text}}</div>
          </div>
        </ng-template>
        <ng-template #mobile_url>
          <div *ngFor="let sub_content of content.sub_items" class="sub-mobile-item">
            <a [href]="sub_content.url">
              <img *ngIf="sub_content.img" [src]="sub_content.img" width="100%" height="100%"/>
              <div class="sub-mobile-text">{{sub_content.text}}</div>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <app-profile-side id="page-side"></app-profile-side>
</main>
