<div class="nav" id="profile-nav">
  <ul>
    <li>
      <div class="sidebar-show"><div class="nav-link"><i style="font-size: 2rem;" class="fas fa-bars"></i></div></div>
    </li>
    <li></li>
    <li>
      <a href="/">
        <div class="nav-link">
          <i style="font-size: 2rem" class="fas fa-home"></i>
        </div>
      </a>
    </li>
    <li>
      <div class="nav-active" style="color: white">
        <i style="font-size: 1.8rem" class="fas fa-user"></i>
      </div>
    </li>
    <li class="half">
      <div class="nav-active" style="color: white; border-top: 1px solid rgb(23,23,23);">
        <i style="font-size: 1rem" class="fas fa-sort-down"></i>
      </div>
    </li>
    <li>
      <div class="nav-active nav-item active" id="nmain" (click)="selectType('main')">
        <i class="fas fa-id-badge" style="font-size: 2rem"></i>
      </div>
    </li>
    <li>
      <div class="nav-active nav-item" id="nweb" (click)="selectType('web')">
        <i class="fas fa-code" style="font-size: 1.6rem"></i>
      </div>
    </li>
    <li>
      <div class="nav-active nav-item" id="ndesign" (click)="selectType('design')">
        <i class="fas fa-paint-brush" style="font-size: 2rem"></i>
      </div>
    </li>
    <!--<li>
      <div class="nav-active nav-item" id="ngame_lvl" (click)="selectType('game_lvl')">
        <i class="fas fa-drafting-compass" style="font-size: 2rem"></i>
      </div>
    </li>-->
    <!--<li>
      <div class="nav-active nav-item" id="ngame_ui" (click)="selectType('game_ui')">
        <i class="fas fa-expand" style="font-size: 2rem"></i>
      </div>
    </li>-->
    <!--<li>
      <div class="nav-active nav-item" id="ngame_fx" (click)="selectType('game_fx')">
        <i class="fas fa-skull-crossbones" style="font-size: 2rem"></i>
      </div>
    </li>-->
    <!--<li>
      <div class="nav-active nav-item" id="nprog" (click)="selectType('prog')">
        <i class="fas fa-cogs" style="font-size: 1.6rem"></i>
      </div>
    </li>-->
    <!--<li>
      <div class="nav-active nav-item" id="nphoto" (click)="selectType('photo')">
        <i class="fas fa-camera" style="font-size: 2rem"></i>
      </div>
    </li>-->
  </ul>
</div>
