<div class="nav" id="nav-mobile"><div class="sidebar-show"><div class="nav-link"><i class="fas fa-bars" style="font-size:2rem;"></i></div></div></div><div class="nav" id="nav"><ul><li><div class="sidebar-show"><div class="nav-link"><i class="fas fa-bars" style="font-size:2rem;"></i></div></div></li><li></li><li><a href="/"><div class="nav-link"><i class="fas fa-home" style="font-size:2rem;"></i></div></a></li><li><a href="/profile"><div class="nav-link"><i class="fas fa-user" style="font-size:1.8rem;"></i></div></a></li><li><a href="/git"><div class="nav-link"><i class="fas fa-code" style="font-size:1.5rem;"></i></div></a></li></ul></div><nav class="nav" id="sidebar"><div class="sidebar-theme"><div class="sidebar-header"><div class="brand"><span><img src=""></span><span>beaend</span></div><div class="sidebar-close"><i class="fas fa-times"></i></div></div><div class="sidebar-content"><!--Search--><!--.sidebar-search.input-group
    input(type="text", placeholder="Search...").form-control.search-menu
    .input-group-append
        span.input-group-text
            i(aria-hidden="true").fa.fa-search--><!--Menu--><div class="sidebar-menu"><ul><li><a href="#"><i class="fa fa-home"></i><span>Главная</span></a></li><!--lia(href="#")
    i.fa.fa-calendar
    span
      | Блог
      = ' '
    span.badge.badge-pill.badge-warning New--><li class="sidebar-dropdown"><span><i class="fa fa-user"></i><span>Портфолио</span></span><div class="sidebar-submenu"><ul></ul><li><a href="/profile">Главная</a></li><!--lia(href='/profile/web') Web--><!--lia(href='/profile/photo') Фото--><!--lia(href='/profile/glvl')
  span.game Game
  |LVL-дизайн--><!--lia(href='/profile/gui')
  span.game Game
  |UX/UI--><!--lia(href='/profile/gfx')
  span.game Game
  |Fx--></div></li><!--li.sidebar-dropdownspan
    i.fa.fa-shopping-cart
    span Магазин
    span.badge.badge-pill.badge-danger 3
.sidebar-submenu
    ul
        li
            a(href="#") Главная
        li
            a(href="#") Расширения
        li
            a(href="#") Кастомизация
        li
            a(href="#") Премиум--><!--li.sidebar-dropdownspan
    i.fas.fa-cogs
    span Theme
    span.badge.badge-pill.badge-info 3
.sidebar-submenu#colored-submenu
    ul
        li
            a(href='#').theme-basic Обычный
        li
            a(href='#').theme-chiller chiller
        li
            a(href='#').theme-lb Light Blue--><!--lia(href="#")
    i.fa.fa-address-card
    span Контакты--></ul></div><div class="sidebar-menu"><ul><li><a href="https://vk.com/beaend" target="_blank"><i class="fab fa-vk"></i><span>VK</span></a></li><li><a href="https://codepen.io/beaend" target="_blank"><i class="fab fa-codepen"></i><span>CodePen</span></a></li><li><a href="https://github.com/Beaend" target="_blank"><i class="fab fa-github"></i><span>GitHub</span></a></li><li><a href="#" target="_blank"><i class="fab fa-behance"></i><span>Behance</span></a></li></ul></div></div><div class="sidebar-footer"><!--a(href="#")i.fa.fa-bell
span.badge.badge-pill.badge-warning.notification 3--><!--a(href="#")i.fa.fa-envelope
span.badge.badge-pill.badge-success.notification 7--><!--a(href="#")i.fa.fa-cog
span.badge-sonar--><!--a(href="#")i.fa.fa-power-off--><button class="theme-basic">Обычный</button><button class="theme-chiller">Chiller</button><button class="theme-lb">Light Blue</button></div></div></nav><div id="sidebar-overlay"></div>