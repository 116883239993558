// app/app-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {ProfileComponent} from "./profile/profile.component";
import {IndexComponent} from "./index/index.component";
import {TestComponent} from "./test/test.component";
import {BlogComponent} from './blog/blog.component';

const appRoutes: Routes = [
  {path: 'profile', component: ProfileComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'test', component: TestComponent},
  {path: '', component: IndexComponent },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
