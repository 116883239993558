import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Input, Output, EventEmitter} from "@angular/core";
import {profile_one} from "./profile-open-one";
import {ModalService} from "../../_services";
import { SafePipe } from "../../_components";

declare var $: any;

@Component({
  selector: 'app-profile-open',
  templateUrl: './profile-open.component.html',
  styleUrls: ['./profile-open.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ProfileOpenComponent implements OnInit {
  @Input() content_id;
  //@Output() closeModal = new EventEmitter();
  profile = profile_one[0];

  constructor(private modalService: ModalService) {}

  closeModal(id: string) {
      this.modalService.close(id);
  }



  public ngOnInit() {
  }

}
