import {Component, OnInit, ViewEncapsulation, Output, HostListener} from '@angular/core';
import { ModalService } from '../_services';
import {profile_content} from './profile-content/profile-content';
import {Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss',
              '../../stylings/modal.less'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {
  title = 'beaend - profile';



  constructor(private modalService: ModalService, private titleService: Title) {
    this.titleService.setTitle(this.title);
  }
  size;
  currentWindowWidth = window.innerWidth;
  contents = profile_content;

  openModal(id: string) {
      this.modalService.open(id);
  }

  public ngOnInit() {
    if (this.currentWindowWidth < 768) {
      this.size = 'mobile';
    }
    else if ((this.currentWindowWidth > 767) && (this.currentWindowWidth < 1280)) {
      this.size = 'tablet';
    }
    else if ((this.currentWindowWidth > 1279) && (this.currentWindowWidth < 1920)) {
      this.size = 'low';
    }
    else if ((this.currentWindowWidth > 1919) && (this.currentWindowWidth < 2560)) {
      this.size = 'medium';
    }
    else if (this.currentWindowWidth > 2559) {
      this.size = 'big';
    }
    console.log(this.size);

    window.onload = function() {
      document.getElementById('main').className += ' active';
      $('.content-item').height($('.active .content-item').width() / 1.6);
      $('.sub-item').height($('.active .sub-item').width() / 1.6);
      $('.sub-mobile-item').height($('.active .sub-mobile-item').width() / 1.6);
    };
    $(function() {
      $('.content-item').height($('.active .content-item').width() / 1.6);
      $('.active .content-item').height($('.active .content-item').width() / 1.6);

      $(window).resize(function() {
        $('.content-item').height($('.active .content-item').width() / 1.6);
      });
      $(window).resize(function() {
        $('.active .content-item').height($('.active .content-item').width() / 1.6);
      });
    });
    $(function() {
      $('.sub-item').height($('.active .sub-item').width() / 1.6);
      $('.active .sub-item').height($('.active .sub-item').width() / 1.6);
      $(window).resize(function() {
        $('.sub-item').height($('.active .sub-item').width() / 1.6);
        $('.active .sub-item').height($('.active .sub-item').width() / 1.6);
      });
    });

    $(function() {
      $('.sub-mobile-item').height($('.active .sub-mobile-item').width() / 1.6);
      $(window).resize(function() {
        $('.sub-mobile-item').height($('.active .sub-mobile-item').width() / 1.6);
      });
    });
    this.onSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
    if (this.currentWindowWidth < 768) {
      this.size = 'mobile';
      console.log(this.size);
      this.onSize();
    }
    else if ((this.currentWindowWidth > 767) && (this.currentWindowWidth < 1280)) {
      this.size = 'tablet';
      console.log(this.size);
      this.onSize();
    }
    else if ((this.currentWindowWidth > 1279) && (this.currentWindowWidth < 1920)) {
      this.size = 'low';
      console.log(this.size);
      this.onSize();
    }
    else if ((this.currentWindowWidth > 1919) && (this.currentWindowWidth < 2560)) {
      this.size = 'medium';
      console.log(this.size);
      this.onSize();
    }
    else if (this.currentWindowWidth > 2559) {
      this.size = 'big';
      console.log(this.size);
      this.onSize();
    }
  }
  onSize() {
    if (this.size === 'mobile') {
      $(function() {
        $('#nav-mobile').css({display: "none"});
        $('#mobile-nav').css({display: "block"});
      });
    }
    else if (this.size === 'tablet') {
      $('#mobile-nav').css({display: "none"});
    }
    else if (this.size === 'low') {
      $('#mobile-nav').css({display: "none"});
    }
    else if (this.size === 'medium') {
      $('#mobile-nav').css({display: "none"});
    }
    else if (this.size === 'big') {
      $('#mobile-nav').css({display: "none"});
    }
  }
}

