import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.pug',
  //styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {
  title = 'Look jQuery Animation working in action!';

  public ngOnInit()
  {
    $(".sidebar-close").click(function() {
        $("main").removeClass("shifted");
        $("nav").removeClass("shifted");
        $("#nav").removeClass("shifted");
        $("#nav-mobile").removeClass("shifted");
    });
    $(".sidebar-show").click(function() {
        $("main").addClass("shifted");
        $("nav").addClass("shifted");
        $("#nav").addClass("shifted");
        $("#nav-mobile").addClass("shifted");
    });

    $(".sidebar-dropdown > span").click(function() {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    });

    $(".theme-basic").click(function() {
        $(".nav").removeClass("chiller-theme");
        $(".nav").removeClass("theme-lightblue");
    });
    $(".theme-chiller").click(function() {
        $(".nav").addClass("chiller-theme");
        $(".nav").removeClass("theme-lightblue");
    });
    $(".theme-lb").click(function() {
        $(".nav").addClass("theme-lightblue");
        $(".nav").removeClass("chiller-theme");
    });

  }
}
