export const posts = [
  {
    name: 'Пост типа code',
    subname: 'Пост ориентированный на код.',
    type: 'code',
    price: 799,
    description: 'Пост в котором выложил какой-то код. ',
    code: true,
    angular: true
  },
  {
    name: 'Пост типа game',
    subname: 'Пост с fx, ui или lvl-дизайном',
    type: 'game',
    price: 699,
    description: 'Пост связанный с гейм-девом',
    g_lvl: true,
    g_ui: true,
    g_fx: true
  },
  {
    name: 'Пост типа design',
    subname: 'именно как дизайн расматривать',
    type: 'design',
    price: 699,
    description: 'Пост с каким-то дизайном. Веб-приколюха, фоточка, картинка, видео...',
    design: true,
  },
  {
    name: 'Обычный пост',
    subname: 'Обычный subname',
    price: 299,
    description: '',
    angular: true,
    python: true,
    design: true
  }
];
