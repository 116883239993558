<div *ngIf="profile[content_id]; then ModalWindow_bg"></div>
<ng-template #ModalWindow_bg>
<div class="ModalWindow_bg">
  <article class="ItemPreview">
    <header class="ItemPreview_header">
      <div *ngIf="profile[content_id].live_url; then avatar_with_url else avatar_without_url"></div>
      <ng-template #avatar_with_url>
        <a [href]="profile[content_id].live_url" class="ItemPreview_header_avatar" target="_blank">
          <img [src]="profile[content_id].icon_url" [alt]="profile[content_id].name">
        </a>
      </ng-template>
      <ng-template #avatar_without_url>
        <img *ngIf="profile[content_id].live_url" [src]="profile[content_id].icon_url" [alt]="profile[content_id].name">
      </ng-template>
      <div class="ItemPreview_header_title">
        <h1>
          <a [href]="profile[content_id].live_url" target="_blank"> {{ profile[content_id].name }}</a>
        </h1>
        <div>
          <a class="ItemPreview_header_title_sub" [href]="profile[content_id].live_url"> {{ profile[content_id].sub_name}} </a>
        </div>
      </div>
      <div class="ItemPreview_header_actions">
        <button *ngIf='profile[content_id].header.todoone' class="button heart-button" title="Like">
          <span class="screen-reader-text">Love</span>
          <i class="fas fa-heart icon-heart"></i>
        </button>
        <a *ngIf="profile[content_id].type == 'site'" [href]="profile[content_id].live_url" class="button button-medium button-no-right-margin" target="_blank">Перейти на сайт</a>
        <a *ngIf="profile[content_id].type == 'pen'" [href]="profile[content_id].live_url" class="button button-medium button-no-right-margin" target="_blank">Посмотреть в редакторе</a>
      </div>
    </header>
    <div *ngIf="profile[content_id].type == 'site'" >
      <div class="ItemPreview_preview">
        <div class="ItemPreview_preview_resizer">
          <iframe [src]="profile[content_id].live_url | safe" frameborder="0" scrolling="no"></iframe>
        </div>
      </div>
      <div class="ItemPreview_details">
        <!--<div class="ItemPreview_details_item flex-item flex-half">
          <div class="ItemPreview_details_share">
            <span>Share</span>
            <div class="ItemPreview_details_share_ButtonsContainer">
              <a href="" class="button button-medium" title="Share on Twitter" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="" class="button button-medium" title="Share on DEV.to" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-dev"></i>
              </a>
              <a href="" class="button button-medium" style="white-space: nowrap;">
                <i class="fas fa-link"></i>
                Copy Link
              </a>
            </div>
          </div>
        </div>
        <div class="ItemPreview_details_item flex-item flex-half">
          <div class="ItemPreview_details_MetaData">
            По заказу:
            <a class="ItemPreview_details_MetaData_for" [href]="profile[content_id].sites_block.site_owner_url" [title]="profile[content_id].sites_block.site_owner">
              <img [src]="profile[content_id].sites_block.site_owner_logo" [alt]="profile[content_id].sites_block.site_owner">
              <span></span>
              <small>{{ profile[content_id].sites_block.site_owner}}</small>
            </a>
          </div>
        </div>-->
        <div class="ItemPreview_details_item flex-item flex-full">
          <pre class="ItemPreview_details_description" tabindex="0">{{profile[content_id].description}}</pre>
        </div>
      </div>
    </div>
    <div *ngIf="profile[content_id].type == 'pen'" >
      <div class="ItemPreview_preview">
        <div class="ItemPreview_preview_resizer">
          <iframe [src]="profile[content_id].live_url | safe" frameborder="0" scrolling="no" sandbox="allow-scripts allow-pointer-lock allow-same-origin"></iframe>
        </div>
      </div>
      <div class="ItemPreview_details">
        <!--<div class="ItemPreview_details_item flex-item flex-half">
          <div class="ItemPreview_details_share">
            <span>Share</span>
            <div class="ItemPreview_details_share_ButtonsContainer">
              <a href="" class="button button-medium" title="Share on Twitter" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="" class="button button-medium" title="Share on DEV.to" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-dev"></i>
              </a>
              <a href="" class="button button-medium" style="white-space: nowrap;">
                <i class="fas fa-link"></i>
                Copy Link
              </a>
            </div>
          </div>
        </div>
        <div class="ItemPreview_details_item flex-item flex-half">
          <div *ngIf="profile[content_id].pen_block.pen_forked" class="ItemPreview_details_MetaData">
            Forked from: <a class="ItemPreview_details_MetaData_for" [href]="profile[content_id].pen_block.pen_forked_url" [title]="profile[content_id].pen_block.pen_forked_owner">{{ profile[content_id].pen_block.pen_forked_owner}}</a>
          </div>
        </div>-->
        <div class="ItemPreview_details_item flex-item flex-full ItemPreview_details_footer">
          <pre class="ItemPreview_details_description" tabindex="0">{{profile[content_id].description}} </pre>
        </div>
      </div>
    </div>
    <div *ngIf="profile[content_id].type == 'photo'" >
      <div class="ItemPreview_preview">
        <div class="ItemPreview_preview_resizer">
          <iframe [src]="profile[content_id].live_url | safe" frameborder="0"></iframe>
        </div>
      </div>
      <div class="ItemPreview_details">
        <div class="ItemPreview_details_item flex-item flex-full ItemPreview_details_footer">
          <pre class="ItemPreview_details_description" tabindex="0">{{profile[content_id].description}} </pre>
        </div>
      </div>
    </div>
  </article>
</div>
</ng-template>
