<app-nav></app-nav>
<main>
<div id="header">
  <h2>beaend</h2>
  <h3>Блог</h3>
</div>
<div id="blog">
<div *ngFor="let post of posts" class="Post {{post.type}}">
  <div class="Post_header">
    <a class="Post_header_name" [title]="post.name">
      {{post.name}}
      <div *ngIf="post.subname" class="Post_header_subname">
        {{post.subname}}
      </div>
    </a>
    <div class="Post_header_actions">
      <i *ngIf="post.angular" class="fab fa-angular code"></i>
      <i *ngIf="post.python" class="fab fa-python code"></i>
      <i *ngIf="post.code" class="fas fa-code code"></i>
      <i *ngIf="post.g_lvl" class="fas fa-drafting-compass game"></i>
      <i *ngIf="post.g_fx" class="fas fa-magic game"></i>
      <i *ngIf="post.g_ui" class="fas fa-expand game"></i>
      <i *ngIf="post.paint" class="fas fa-paint-brush design"></i>
      <i *ngIf="post.design" class="fas fa-crop design"></i>
    </div>
  </div>
  <div class="Post_body">
    <div *ngIf="post.image" class="Post_body_image">
      <img src="" />
    </div>
    <div *ngIf="post.description" class="Post_text">
      Description: {{post.description}}
    </div>
  </div>
  <div class="Post_footer">
    <button (click)="share()">
      Alert
    </button>
  </div>
</div>
</div>
  <div class="ModalWindow_BG">
    <div class="ModalWindow ModalInfo">
      <div class="ModalHeader">
        <i class="fas fa-info-circle"></i>
        Information
      </div>
      <div class="ModalBody">
        Это модальное окно с информацией.
      </div>
      <div class="ModalFooter">
        <button (click)="close()">OK</button>
      </div>
    </div>
  </div>
  <div class="ModalWindow_BG">
    <div class="ModalWindow ModalCustom">
      <div class="ModalHeader">
        <i class=""></i>
        Custom modal
      </div>
      <div class="ModalBody">
        <input>some text
      </div>
      <div class="ModalFooter">
        <button (click)="close()">OK</button>
      </div>
    </div>
  </div>
  <div class="ModalWindow_BG">
    <div class="ModalWindow ModalError">
      <div class="ModalHeader">
        <i class="fas fa-exclamation-triangle"></i>
        Error
      </div>
      <div class="ModalBody">
        Это модальное окно с ошибкой.
      </div>
      <div class="ModalFooter">
        <button (click)="close()">Close</button>
      </div>
    </div>
  </div>
  <div class="ModalWindow_BG">
    <div class="ModalWindow ModalConfirm">
      <div class="ModalHeader">
        <i class=""></i>
        Confirm
      </div>
      <div class="ModalBody">
        Ты уверен?
      </div>
      <div class="ModalFooter">
        <button (click)="close()">Да</button>
        <button (click)="close()">Нет</button>
      </div>
    </div>
  </div>
  <div class="ModalWindow_BG">
    <div class="ModalWindow ModalWait">
      <div class="ModalHeader">
        <i class=""></i>
        Please wait
      </div>
      <div class="ModalBody">
        Ожидание.
      </div>
    </div>
  </div>
</main>
